/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://lnqrrr5m2jgxde6xdexcou77vi.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kufa6azycrdpnpuwvizaakzz6m",
    "aws_cognito_identity_pool_id": "ap-south-1:734bcb6f-b575-4a52-a77f-8fdaff08cf05",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_N6KJO3t5i",
    "aws_user_pools_web_client_id": "4fh0vbcu5a0eb3ul35p2g7ftgn",
    "oauth": {
        "domain": "myspherer18fe87578-8fe87578-staging.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://main.d2octyrngvaooa.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://main.d2octyrngvaooa.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "BIRTHDATE",
        "EMAIL",
        "GENDER",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "OFF"
    ],
    "aws_user_files_s3_bucket": "myspherer1aab9fb399c7448369d7fe1142cf8bbfa85014-staging",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
