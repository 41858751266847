import React from 'react';
import './Loader.css';
import Grid from '@mui/material/Grid';

function Loader2() {
  return (
      <Grid className="customLoader2">
        <Grid className='loaderInner'>
            <Grid className='YellowTwo'></Grid>
        </Grid>
      </Grid>
  );
}

export default Loader2;
