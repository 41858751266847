import { configureStore } from '@reduxjs/toolkit'
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { browserHistory } from 'react-router';


import reducers from './reducers';

export default createStore(
    reducers,
    applyMiddleware(
      thunkMiddleware,

    )
  );
  