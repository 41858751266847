import React from 'react';
import './Loader.css';
import Grid from '@mui/material/Grid';

function Loader(props) {
  
  return (
      <>
      {props.visible===true &&
      <Grid className="customLoader">
        <Grid className='loaderInner'>
          <Grid className='GreenOne'></Grid>
            <Grid className='YellowTwo'></Grid>
            <Grid className='GreenThree'></Grid>
        </Grid>
      </Grid>
      }
    </>
  );
  
}

export default Loader;
