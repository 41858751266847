import { SET_EDUCATION } from '../actions/actionTypes';

const initialState = {
  educations: []
};

const setEducations = (state, action) => {
  return { ...state, educations: action.educations };
};

const educationsReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_EDUCATION: return setEducations(state, action);
    default: return state;
  }
};

export default educationsReducer;